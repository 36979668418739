import React from "react";
import { graphql, Link } from "gatsby";
import { TweenMax } from "gsap";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Newsletter from "../../components/newsletter.js";

class SucessPage extends React.Component {
  componentDidMount() {
    setTimeout(() => {
      this.setState({
        isLoading: false,
        status: "Completed!"
      });
    }, 2000);

    var doc = document;

    var a = TweenMax;

    var paths = doc.getElementsByTagName("path");
    paths = [].slice.call(paths);

    paths.forEach(function(path, i) {
      var len = Math.ceil(path.getTotalLength());

      path._len = len;
      path.style.strokeDasharray = len;
      path.style.strokeDashoffset = len;
    });

    var alt = false;

    function pathsReset() {
      paths.forEach(function(path, i) {
        setTimeout(() => {
          a.to(path, 1, { strokeDashoffset: 0 });

          if (i === paths.length - 1) {
            if (alt) {
              setTimeout(pathsOut, 5000);
            } else {
              setTimeout(pathsIn, 5000);
            }
            alt = !alt;
          }
        }, 20 * i);
      });
    }

    function pathsOut() {
      paths = paths.reverse();
      paths.forEach(function(path, i) {
        setTimeout(() => {
          a.to(path, 1, { strokeDashoffset: -path._len });

          if (i === paths.length - 1) pathsReset();
        }, 20 * i);
      });
    }

    function pathsIn() {
      paths.forEach(function(path, i) {
        setTimeout(() => {
          a.to(path, 1, { strokeDashoffset: path._len });

          if (i === paths.length - 1) pathsReset();
        }, 50 * i);
      });
    }

    pathsReset();
  }

  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <div
          style={{
            textAlign: "center",
            paddingTop: "5%",
            width: "100%",
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Link to="/">
            <svg
              version="1.1"
              id="Warstwa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="736 83.3 3392.2 914.7"
              style={{ enableBackground: "new 736 83.3 3392.2 914.7" }}
              xmlSpace="preserve"
            >
              <g>
                <defs>
                  <path
                    id="SVGID_1_"
                    d="M1170,926.1L1013,641h-44.1v285.1H784.9V170.9h308.7c59.5,0,110.3,10.4,152.2,31.2
            c42,20.8,73.3,49.3,94.1,85.5c20.8,36.2,31.2,76.6,31.2,121c0,50.2-14.2,95-42.5,134.5c-28.3,39.4-70.1,67.4-125.3,83.9
            l174.3,299.1H1170z M968.9,510.9h114c33.7,0,59-8.2,75.8-24.7c16.8-16.5,25.3-39.8,25.3-69.9c0-28.7-8.4-51.3-25.3-67.8
            c-16.9-16.5-42.1-24.7-75.8-24.7h-114V510.9z"
                  />
                </defs>
                <clipPath id="SVGID_2_">
                  <use xlinkHref="#SVGID_1_" style={{ overflow: "visible" }} />
                </clipPath>
                <g className="st0">
                  <path
                    className="st1"
                    d="M507.1,457.6c-15.2,94-4.2,192.9,37,285.8c58.8,132.4,167.3,227.8,295.2,271.9c3.6,1.1,10.6,3.6,14.2,4.7
            c25,8.2,50.6,14.7,76.9,18.3c12.1,1.6,36.2,4.2,48.3,5.4c95.3,8.3,194.2-13.9,284.9-63.5c129.3-70.5,217.1-187,253.6-317.3"
                  />
                  <path
                    className="st2"
                    d="M1081.1,1079.7c68.5-8.5,136.8-29.9,201.2-65.1c78.2-42.6,142.1-100.9,190.3-168.6"
                  />
                  <path
                    className="st2"
                    d="M618.4,211.3c-59.6,212.7-34.9,379.9-34.9,379.9c6.5,51.6,22.5,102.5,48.8,150.8
            c114.6,209.9,380.3,285.8,593.6,169.2c165.6-90.4,250.8-269.9,227.2-444.1l0,0c0,0-13.1-88.6,2.8-218.9"
                  />
                  <path
                    className="st1"
                    d="M558.6,302.9C525.7,472.8,544,596.7,544,596.7l0,0c7.2,56.1,24.6,112,53.2,164.5
            c124.8,229,414.7,311.6,647.4,184.6c67.7-36.9,124.7-86.5,164.7-146.6c7-10.4,20.4-31.8,26.8-42.6
            c41.8-70.7,63.6-152.6,60.7-234.4c-0.5-15.2-2.6-45.5-4.2-60.5c-4.1-38-8.8-59.7-3.3-153.1"
                  />
                  <path
                    className="st2"
                    d="M822.3,58.7c-54.7,127.1-76.7,247.6-83.1,339.5c-0.7,8.3-1.6,25-2.1,33.5c-2,35.4-1.1,65.3-0.2,88
            c1.5,31.5,4.1,49.3,4.1,49.3l0,0c4.1,33.1,14.4,65.9,31.2,96.8c73,133.8,242.5,182.1,378.5,107.9
            c105.9-57.8,160.3-172.8,144.8-284l0,0c0,0-24.6-164.7,33.1-374.9"
                  />
                  <path
                    className="st2"
                    d="M988.1,18.8c-125.7,277.3-89.4,528-89.4,528l0,0c1.6,14.5,6.2,29,13.5,42.6c31.5,57.8,104.6,78.5,163.2,46.5
            c46.2-25.1,69.7-75.6,62.2-124l0,0c0,0-32.2-213.6,61.9-464.6"
                  />
                  <path
                    className="st2"
                    d="M1025.8,17.3c-123.4,275.5-87.6,523.1-87.6,523.1c1,10.1,4.1,20.2,9.3,29.7c21.1,38.7,70.2,52.7,109.5,31.2
            c31.2-17,47-51.2,41.6-84c0,0-33.6-222.8,68.1-480.8"
                  />
                  <path
                    className="st2"
                    d="M1062.4,18.6C942.6,292.1,978,537.7,978,537.7c3.3,22.2,24.2,37.4,46.5,33.8c22.5-3.6,38-24.5,34.8-46.7
            c0,0-3.4-19.9-4.6-54.7c-0.3-9.1-0.8-27.7-0.8-36.9c-0.3-18.9,0.2-40.5,1.8-64.1c0.7-9.5,2.1-28.2,2.9-37.7
            c2.9-33.8,8.2-70.8,16.2-110.2c1-4.4,2.8-13.4,3.8-17.8c11.8-55.2,29.4-114.2,54.3-175.3"
                  />
                  <path
                    className="st2"
                    d="M1097.9,22.4c-114.7,269.4-79.3,509-79.3,509"
                  />
                  <path
                    className="st2"
                    d="M949.3,23c-126.3,277.8-89.9,529.1-89.9,529.1l0,0c2.3,19.3,8.2,38.2,18,56.1c5.5,10.1,11.3,19.9,19.1,27.6
            c9,8.6,28.1,24.8,38.4,32c44.6,31.5,107.5,30.7,159.6,2.3c61-33.3,92.2-99.9,82.9-164l0,0c0,0-11.3-67.7-2.3-171.5
            c1-10.8,2.9-32.2,4.1-42.8c5.5-52.9,17.1-112.9,35.7-176.6c5.2-18.1,11.3-36.6,17.8-55.2"
                  />
                  <path
                    className="st2"
                    d="M908.8,30.7c-10.4,23-19.9,45.9-28.1,68.4c-3.9,11.3-11.8,33.8-15.5,45.2c-44.7,135.9-51.9,257.5-50.3,332.8
            c0.3,11.6,1.1,34.6,1.6,46.2c1.1,22.2,3.3,34.6,3.3,34.6l0,0c2.9,23.8,10.3,47.5,22.4,69.7c52.2,95.8,173.5,130.4,270.9,77.2
            c76.1-41.5,114.9-124.2,103.5-204c0,0-28.7-191.6,48.1-425.3"
                  />
                  <path
                    className="st2"
                    d="M866.5,42.4c-121.9,274.5-86,520.8-86,520.8c3.4,28.6,12.2,56.8,26.8,83.4c56,102.7,179.8,151.3,286.7,109.3
            c9.6-3.8,28.6-12.1,37.9-16.6c55.5-27.3,95.6-75.9,112.1-130.4c3.6-11.8,9.5-35.4,11.9-47.5c4.2-21.7,3.3-44.1,0.2-66.3l0,0
            c0,0-26.8-178.9,40.8-401.6"
                  />
                  <path
                    className="st2"
                    d="M775.8,81.3c-107.7,263.2-74.1,493.2-74.1,493.2l0,0c4.7,37.7,16.5,75.1,35.6,110.3
            c27.1,49.8,65.9,89.3,111.5,116.9c3.4,2.1,6.7,4.1,10.1,5.9c53.7,29.9,115.7,44.7,177.9,39.5c4.7-0.3,14-1.3,18.8-1.8
            c33.8-3.9,67.4-14.2,99.7-29.9c4.7-2.3,9.6-4.6,14.2-7.3c8.3-4.9,24.8-15.2,33-20.6c101-67.4,148.4-188.7,132.4-303.4l0,0
            c0,0-4.6-30.5-5.1-82.1c-0.7-62.3,4.4-155.5,30.5-262.4"
                  />
                  <path
                    className="st2"
                    d="M726.7,111.5c-96,252.6-64.3,468.6-64.3,468.6c5.2,42.3,18.4,84.4,40,123.9
            c44.7,81.9,116.5,141.5,200.2,165.6c11.4,3.3,34.3,9.1,45.9,11.6c78.3,17.1,163.5,2.9,239.9-38.7
            c135.8-74.1,205.6-221.5,185.9-364.1l0,0c0,0-19.6-131.5,17.5-309.6"
                  />
                  <path
                    className="st2"
                    d="M674.3,152.8c-80.1,237-51.4,432.8-51.4,432.8c5.9,47,20.6,93.5,44.4,137.4c4.4,8,8.6,16,13.9,23.3
            c7.5,10.9,23.2,32.2,31.3,42.4c17.5,22,38.2,40.6,60.2,57.1c2.9,2.1,8.8,6.5,11.8,8.6c119,85.5,283.5,98.4,422.7,22.5
            c150.6-82.3,228.3-245.6,206.6-404.1l0,0c0,0-12.2-75.2-0.7-188.8c1-9.6,3.1-29,4.2-38.7c1.6-13.4,3.8-27.1,6.2-41.3"
                  />
                </g>
              </g>
              <g>
                <defs>
                  <path
                    id="SVGID_3_"
                    d="M1670.2,318.3v152.8h246.3v142h-246.3v165.7h278.6v147.4h-462.6V170.9h462.6v147.4H1670.2z"
                  />
                </defs>
                <clipPath id="SVGID_4_">
                  <use xlinkHref="#SVGID_3_" style={{ overflow: "visible" }} />
                </clipPath>
                <g className="st3">
                  <path
                    className="st1"
                    d="M1085.6,511.3c-14.2,107.9,1.3,221,51.1,326.7c70.8,150.6,197.1,258.3,343.7,306.7c4.1,1.3,12.2,3.9,16.3,5.2
            c28.6,9,57.9,16,87.8,19.7c13.7,1.6,41.3,4.4,55,5.4c108.5,8,220.2-19.1,321.5-77.2c144.4-82.7,240.6-217.5,277.9-367.2"
                  />
                  <path
                    className="st2"
                    d="M1756.9,1214.5c77.5-10.9,154.4-36.4,226.4-77.8c87.3-50.1,158.3-117.8,210.9-196"
                  />
                  <path
                    className="st2"
                    d="M1204.1,227.5c-60.9,244.3-27.6,435.6-27.6,435.6c9,58.9,28.9,117,60.2,171.9
            c136.6,238.4,440.8,321,679.4,184.4c185.2-106.1,276.3-313.2,244-512.1l0,0c0,0-17.6-101.3-3.9-250.7"
                  />
                  <path
                    className="st1"
                    d="M1139.2,333.4c-31.8,195-7.2,336.7-7.2,336.7l0,0c9.8,64.1,31.5,127.8,65.6,187.5
            c149,260.1,480.8,350.2,740.9,201.1c75.7-43.4,138.9-101.2,182.3-170.4c7.5-12.1,22-36.7,29.1-49.1
            c45.4-81.6,67.6-175.8,61.5-269.3c-1.1-17.3-4.4-52.1-6.7-69.4c-5.9-43.4-11.9-68.2-8.5-175.1"
                  />
                  <path
                    className="st2"
                    d="M1430.8,49.6c-58.1,146.4-79.2,284.6-83.6,390.1c-0.3,9.6-1,28.7-1.3,38.2c-1,40.5,0.7,74.7,2.6,100.7
            c2.6,35.9,6.2,56.3,6.2,56.3l0,0c5.7,37.9,18.4,75.2,38.5,110.3c87.1,152.1,281,204.7,433.1,117.5
            c118.3-67.9,176.4-200.4,155.4-327.5l0,0c0,0-33.1-188.2,25.8-429.9"
                  />
                  <path
                    className="st2"
                    d="M1617.8,1.1c-134,319.5-84.7,605.8-84.7,605.8l0,0c2.4,16.6,7.8,33.1,16.8,48.6
            c37.5,65.6,121.3,88.3,186.9,50.8c51.6-29.5,76.7-87.6,66.7-143l0,0c0,0-43.2-244,55.7-533"
                  />
                  <path
                    className="st2"
                    d="M1660.4-1c-131.4,317.3-82.9,600.4-82.9,600.4c1.5,11.6,5.2,23,11.4,33.8c25.1,43.9,81.3,59.2,125.3,33.9
            c34.9-19.9,51.7-59.4,44.6-96.8c0,0-45.2-254.6,62-551.6"
                  />
                  <path
                    className="st2"
                    d="M1702-0.2c-127.5,315.1-79.5,595.7-79.5,595.7c4.4,25.5,28.6,42.4,54,38c25.5-4.4,42.4-28.6,38-54
            c0,0-4.4-22.7-6.9-62.7c-0.7-10.6-1.6-31.7-2.1-42.3c-0.8-21.7-1-46.3,0-73.4c0.5-10.8,1.5-32.3,2.1-43.1
            c2.3-38.7,7-81.3,14.9-126.5c0.8-5.1,2.8-15.3,3.6-20.4c11.6-63.3,29.7-131.4,56.1-201.4"
                  />
                  <path
                    className="st2"
                    d="M1742.7,3.6c-121.7,310.4-73.9,584.1-73.9,584.1"
                  />
                  <path
                    className="st2"
                    d="M1573.8,6.7c-134.6,320-85.2,607.3-85.2,607.3l0,0c3.3,21.9,10.4,43.6,22.2,64c6.5,11.4,13.4,22.5,22.7,31.3
            c10.4,9.8,32.6,27.9,44.6,35.9c51.6,35.3,123.1,33.5,181.3,0.2c68.2-39.2,101.7-115.9,88.9-189.2l0,0c0,0-14.9-77.4-8-196.3
            c0.7-12.2,2.3-36.9,3.3-49.1c4.7-60.7,15.8-129.4,34.9-202.9c5.4-20.9,11.6-42.1,18.4-63.5"
                  />
                  <path
                    className="st2"
                    d="M1528.1,16.1c-11.1,26.6-21.2,52.7-29.7,78.8c-4.2,12.9-12.2,39-16.2,52.1c-46.5,156.3-50.9,295.6-46.5,381.9
            c0.7,13.2,2.3,39.7,3.3,52.9c2,25.5,4.7,39.5,4.7,39.5l0,0c4.1,27.3,13.1,54.2,27.6,79.5c62.3,108.9,201.1,146.6,309.9,84.2
            c85-48.6,126.6-143.9,111-235.2c0,0-38.7-219,41.3-487.8"
                  />
                  <path
                    className="st2"
                    d="M1480.6,30.2c-129.7,316.1-81.3,597.6-81.3,597.6c4.9,32.6,15.8,64.8,33.1,95.1
            c66.7,116.7,208.9,170.2,329,120.6c10.8-4.4,32.2-14.4,42.4-19.7c62.2-32.2,106.1-88.5,123.2-151c3.8-13.4,9.6-40.8,11.9-54.5
            c4.1-24.8,2.3-50.6-2-75.9l0,0c0,0-36.1-204.3,33.6-460.6"
                  />
                  <path
                    className="st2"
                    d="M1378.7,76c-114.1,303.1-68.5,566-68.5,566l0,0c6.5,43.1,21.1,85.7,43.9,125.8c32.5,56.6,77.7,101,130.2,132
            c3.9,2.3,7.8,4.6,11.8,6.7c61.9,33.5,132.8,49.5,203.3,42.4c5.4-0.5,16-1.8,21.2-2.4c38.4-4.9,76.2-17.3,112.3-35.7
            c5.4-2.8,10.8-5.4,16-8.6c9.3-5.7,27.7-17.8,36.7-24.2c112.6-78.7,162.4-218.4,140.7-349.6l0,0c0,0-6.2-34.9-8.3-93.8
            c-2.8-71.3,0.2-178.1,26.3-301.1"
                  />
                  <path
                    className="st2"
                    d="M1323.7,111.5c-100.9,290.8-58.1,537.4-58.1,537.4c7.3,48.3,23.7,96.3,49.5,141.3
            c53.4,93,136.8,160.3,232.6,186.5c13.1,3.6,39.2,9.8,52.4,12.6c89.4,18.4,185.7,0.8,271.2-48.1c151.8-87,226.4-256.7,199.6-419.8
            l0,0c0,0-26.4-150.3,10.1-354.8"
                  />
                  <path
                    className="st2"
                    d="M1265.6,159.6c-83.6,272.7-44.7,496.5-44.7,496.5c8.2,53.7,26.3,106.7,54.8,156.7
            c5.2,9.1,10.4,18.1,16.5,26.6c8.8,12.2,27.3,36.4,36.9,48c20.6,25,44.7,46,70.2,64.5c3.4,2.4,10.3,7.3,13.7,9.6
            c137.9,96,325.1,108.2,480.6,18.9c168.4-96.6,251.3-285,221.8-465.9l0,0c0,0-16.2-86-6.7-216.1c0.8-11.1,2.6-33.3,3.6-44.4
            c1.5-15.3,3.4-31.2,5.7-47.3"
                  />
                </g>
              </g>
              <g>
                <defs>
                  <path
                    id="SVGID_5_"
                    d="M2530.8,926.1L2377,694.8l-135.5,231.3h-208.7l242-384l-247.4-371.1h214.1L2393.1,399l133.4-228.1h208.7
            l-239.9,380.8l249.6,374.4H2530.8z"
                  />
                </defs>
                <clipPath id="SVGID_6_">
                  <use xlinkHref="#SVGID_5_" style={{ overflow: "visible" }} />
                </clipPath>
                <g className="st4">
                  <path
                    className="st1"
                    d="M1801.2,401.1c-23.3,106.2-17.5,220.2,23,329.8c57.8,156.2,174.5,274.2,316.3,334.7
            c3.9,1.6,11.8,4.9,15.7,6.5c27.7,11.4,56.3,20.9,85.8,27.1c13.5,2.8,40.8,7.8,54.3,10.1c107.4,17.3,221-0.3,326.9-49.5
            c151-70.2,258.2-196.3,308.3-342.2"
                  />
                  <path
                    className="st2"
                    d="M2410.2,1159c78.2-4.2,156.8-23.2,232.2-58.3c91.2-42.4,167.8-103.8,226.7-177.4"
                  />
                  <path
                    className="st2"
                    d="M1943.5,128.5c-81.4,238.3-64.6,431.7-64.6,431.7c3.9,59.4,18.8,119.1,45.4,176.4
            c115.9,249.2,411.9,357.4,661.1,241.5c193.6-89.9,301.9-288.5,286.6-489.4l0,0c0,0-9-102.5,17.5-250"
                  />
                  <path
                    className="st1"
                    d="M1869.7,228.4c-48.3,191.6-35.9,334.7-35.9,334.7l0,0c4.4,64.8,20.4,129.9,49.5,192.4
            c126.3,271.9,449.3,389.9,721,263.4c79.2-36.7,146.9-88.9,196-154.2c8.6-11.4,25.1-34.8,33.1-46.5
            c52.1-77.4,82.3-169.4,84.2-263.1c0.3-17.5,0-52.2-0.8-69.7c-2.1-43.7-6-69,6.4-175.3"
                  />
                  <path
                    className="st2"
                    d="M2184.5-29.5c-70.3,141-103.1,276.8-116.5,381.6c-1.1,9.5-3.4,28.6-4.6,38c-4.4,40.3-5.7,74.6-6,100.7
            c-0.5,36.1,1.3,56.6,1.3,56.6l0,0c2.4,38.2,11.9,76.5,29,113.3c73.9,159,262.6,227.8,421.5,154.1
            c123.7-57.4,192.9-184.6,182.6-313.2l0,0c0,0-17-190.3,62.3-426.1"
                  />
                  <path
                    className="st2"
                    d="M2375-61.9c-160.6,307-136.1,596.5-136.1,596.5l0,0c1,16.8,5.1,33.6,12.6,49.9
            c31.8,68.5,113.3,98.2,181.8,66.4c53.9-25.1,83.9-80.8,78.7-136.8l0,0c0,0-22.4-246.8,100.9-526.3"
                  />
                  <path
                    className="st2"
                    d="M2417.6-60.5c-158,305-133.7,591.1-133.7,591.1c0.5,11.8,3.3,23.5,8.5,34.8c21.4,46,75.9,65.9,121.9,44.6
            c36.4-17,56.5-54.8,52.6-92.7c0,0-23.3-257.5,108.9-544.3"
                  />
                  <path
                    className="st2"
                    d="M2459-56.1c-153.7,303.1-129.9,586.7-129.9,586.7c2.3,25.6,24.8,44.7,50.6,42.4c25.6-2.3,44.7-24.8,42.4-50.6
            c0,0-2.4-23-1.5-63c0.3-10.6,1-31.7,1.5-42.3c1-21.7,2.9-46.3,6.2-73.3c1.3-10.8,4.2-32.2,5.7-42.8c5.5-38.4,13.9-80.3,25.5-124.7
            c1.3-5.1,3.9-15,5.4-20.1c16.8-62,40.8-128.3,73.1-196"
                  />
                  <path
                    className="st2"
                    d="M2499-48.9c-147.7,299-123.4,575.6-123.4,575.6"
                  />
                  <path
                    className="st2"
                    d="M2330.6-60.1C2169.2,247.3,2194,537.7,2194,537.7l0,0c1.3,22.2,6.7,44.4,16.6,65.8
            c5.5,11.9,11.4,23.7,19.9,33.1c9.5,10.8,30.2,30.5,41.3,39.7c48.5,39.7,119.8,43.7,180.5,15.5c71.3-33.1,111.1-106.7,104.6-180.8
            l0,0c0,0-8.2-78.3,8.8-196.3c1.8-12.2,5.5-36.6,7.5-48.6c9.8-60.1,26.8-127.6,52.1-199.1c7.2-20.2,15-41,23.8-61.7"
                  />
                  <path
                    className="st2"
                    d="M2284.2-54.6c-13.4,25.5-25.6,50.8-36.2,75.9c-5.2,12.6-15.5,37.7-20.6,50.4
            c-59.7,151.8-75.9,290.2-78.8,376.5c-0.5,13.2-1,39.7-1.1,52.9c-0.2,25.5,1.3,39.7,1.3,39.7l0,0c1.6,27.4,8.5,55,20.7,81.6
            c52.9,113.8,188,163,301.6,110.2c88.8-41.3,138.4-132.7,130.6-224.9c0,0-19.9-221.5,82.7-482.4"
                  />
                  <path
                    className="st2"
                    d="M2235.8-44.8c-156.2,304-131.9,588.7-131.9,588.7c2.1,32.8,10.1,65.9,25,97.6
            c56.6,121.9,193.7,187.4,317.6,148.2c11.1-3.6,33.1-11.6,44.1-16c64.6-26.8,113.3-79.2,135.6-140c4.7-13.1,13.1-39.8,16.5-53.4
            c6.2-24.5,6.5-50.3,4.4-75.7l0,0c0,0-18.4-206.6,72.8-456"
                  />
                  <path
                    className="st2"
                    d="M2130.4-7.6C1991,284.7,2014,550.6,2014,550.6l0,0c2.8,43.4,13.5,87.1,33.1,129.1
            c27.4,59.1,68.9,107.4,118.5,142.6c3.6,2.6,7.3,5.1,11.1,7.7c58.8,38.5,128.1,60.5,198.9,59.6c5.4,0,16-0.3,21.4-0.7
            c38.5-1.6,77.4-10.8,114.9-25.9c5.5-2.3,11.1-4.6,16.6-7.2c9.8-4.9,29.2-15.3,38.7-20.9c118.8-68.9,180.3-203.7,169.9-336.4l0,0
            c0,0-3.1-35.3-0.3-94.2c3.3-71.3,15.3-177.4,51.9-297.7"
                  />
                  <path
                    className="st2"
                    d="M2072.6,22.9c-125.3,281.2-103.6,530.6-103.6,530.6c3.3,48.8,15.3,97.9,37.2,144.9
            c45.2,97.3,122.7,171.2,215.9,205.6c12.6,4.7,38.2,13.2,51.1,17c87.6,25.9,185.1,16.6,274.3-24.8
            c158.6-73.8,247.4-236.6,234.7-401.3l0,0c0,0-13.5-152.1,40.3-352.7"
                  />
                  <path
                    className="st2"
                    d="M2010.7,66c-106.4,264.5-86.8,490.9-86.8,490.9c3.6,54.2,17.1,108.5,41.3,160.8c4.4,9.5,8.8,18.9,14,27.7
            c7.7,13.1,24.2,38.5,32.6,51.1c18.3,26.6,40.6,49.6,64.5,70.2c3.1,2.8,9.6,8.2,12.7,10.8c129.3,107.4,314.7,135.5,477.2,59.9
            c176.1-81.9,274.7-262.6,260.6-445.4l0,0c0,0-8.8-87,11.8-215.9c1.8-10.9,5.5-33,7.3-43.9c2.8-15.2,6-30.7,9.6-46.7"
                  />
                </g>
              </g>
              <g>
                <defs>
                  <path
                    id="SVGID_7_"
                    d="M3021.3,318.3v152.8h246.3v142h-246.3v165.7h278.6v147.4h-462.6V170.9h462.6v147.4H3021.3z"
                  />
                </defs>
                <clipPath id="SVGID_8_">
                  <use xlinkHref="#SVGID_7_" style={{ overflow: "visible" }} />
                </clipPath>
                <g className="st5">
                  <path
                    className="st1"
                    d="M3542.9,709.4c25.8-105.8,22.5-219.8-15.5-330.3c-54.2-157.5-168.1-278.1-308.6-341.9
            c-3.9-1.8-11.8-5.2-15.7-7c-27.4-12.1-55.8-22.2-85.2-29c-13.5-3.1-40.6-8.8-54.2-11.3c-107.1-19.7-220.8-4.7-327.9,41.9
            c-152.6,66.7-262.6,190.3-316,335.1"
                  />
                  <path
                    className="st2"
                    d="M2951.5-62.2c-78.3,2.4-157.3,19.6-233.5,52.9c-92.2,40.3-170.1,100-230.8,172.2"
                  />
                  <path
                    className="st2"
                    d="M3394.4,978.7c87-236.3,74.4-430,74.4-430c-2.6-59.6-16-119.5-41.3-177.4
            c-110.2-251.8-403.6-366.7-655.4-256.7c-195.5,85.5-308.5,281.5-297.7,482.8l0,0c0,0,6.7,102.7-23.2,249.7"
                  />
                  <path
                    className="st1"
                    d="M3470.4,880.5c52.7-190.5,43.6-333.9,43.6-333.9l0,0c-2.9-65-17.5-130.4-45-193.6
            C3348.9,78.3,3028.8-47,2754.1,73.1c-80,34.9-149,85.5-199.6,149.7c-8.8,11.3-25.9,34.1-34.1,45.7c-53.9,76.2-86,167.4-90.3,261.1
            c-0.8,17.5-1.3,52.2-0.8,69.7c1.1,43.9,4.4,69-10.4,175.1"
                  />
                  <path
                    className="st2"
                    d="M3149.9,1131.1c73.6-139.4,109.5-274.5,125.2-378.8c1.5-9.5,4.1-28.4,5.4-37.9c5.4-40.1,7.3-74.4,8.3-100.5
            c1.3-36.1,0-56.6,0-56.6l0,0c-1.6-38.2-10.1-76.7-26.4-113.9c-70.2-160.6-257.4-233.9-417.8-163.5
            c-125,54.7-197.1,180.2-189.8,308.8l0,0c0,0,12.6,190.6-72,424.5"
                  />
                  <path
                    className="st2"
                    d="M2958.8,1159.2C3126.4,856,3108.5,566,3108.5,566l0,0c-0.7-16.8-4.2-33.8-11.4-50.1
            c-30.4-69.2-111-100.9-180.3-70.5c-54.5,23.8-85.7,78.8-81.8,135l0,0c0,0,16.6,247.3-112.8,523.9"
                  />
                  <path
                    className="st2"
                    d="M2916.2,1156.8c164.8-301.3,147.2-587.9,147.2-587.9c-0.2-11.8-2.8-23.5-7.7-34.9
            c-20.2-46.5-74.4-67.6-120.9-47.3c-36.9,16.2-57.8,53.5-54.7,91.4c0,0,17.5,257.9-121.3,541.7"
                  />
                  <path
                    className="st2"
                    d="M2874.9,1151.4c160.8-299.5,143.3-583.6,143.3-583.6c-1.6-25.8-23.8-45.2-49.6-43.6
            c-25.8,1.6-45.2,23.8-43.6,49.6c0,0,2,23,0,63c-0.5,10.6-1.8,31.7-2.4,42.3c-1.5,21.7-3.9,46.2-8,73.1c-1.6,10.6-4.9,32-6.7,42.6
            c-6.4,38.2-15.7,80-28.4,124c-1.5,4.9-4.4,14.9-5.9,19.9c-18.3,61.7-43.6,127.3-77.5,194.2"
                  />
                  <path
                    className="st2"
                    d="M2834.9,1143.4c154.6-295.4,136.6-572.7,136.6-572.7"
                  />
                  <path
                    className="st2"
                    d="M3003.2,1158.4c168.4-303.7,150.3-594.5,150.3-594.5l0,0c-0.8-22.2-5.7-44.6-15.2-66.1
            c-5.2-12.1-10.9-24-19.1-33.6c-9.3-10.9-29.5-31.3-40.3-40.6c-47.5-40.6-118.8-46.5-180.2-19.7
            c-72.1,31.5-113.6,104.1-108.7,178.4l0,0c0,0,6.4,78.5-13.2,196.2c-2,12.1-6.4,36.4-8.6,48.5c-11.3,59.9-29.7,127-56.6,197.8
            c-7.7,20.1-16,40.5-25.1,61.2"
                  />
                  <path
                    className="st2"
                    d="M3049.5,1154c13.9-25.1,26.8-50.1,38-75.1c5.5-12.4,16.3-37.4,21.7-49.9c63.2-150.5,82.4-288.4,87.5-374.5
            c0.8-13.2,2-39.7,2.4-52.9c0.8-25.5-0.5-39.8-0.5-39.8l0,0c-1.1-27.4-7.2-55.3-18.9-81.9c-50.3-114.9-184.1-167.3-299-117
            c-89.8,39.2-141.3,129.4-135.8,222c0,0,14.9,222-93.7,480.5"
                  />
                  <path
                    className="st2"
                    d="M3098.3,1145.2c163.2-300.3,145.2-585.4,145.2-585.4c-1.3-33-8.6-66.1-22.7-98.1
            c-53.9-123.2-189.3-191.8-314.2-155.4c-11.3,3.3-33.5,10.8-44.4,15c-65.3,25.3-115.1,76.5-138.7,136.9
            c-5.1,13.1-14,39.5-17.8,52.9c-6.9,24.3-7.7,50.1-6.2,75.6l0,0c0,0,13.7,207.1-83.1,454.2"
                  />
                  <path
                    className="st2"
                    d="M3204.6,1110.4c146.1-289,129.3-555.2,129.3-555.2l0,0c-1.8-43.6-11.6-87.5-30.2-129.7
            c-26.1-59.7-66.4-108.9-115.2-145.2c-3.6-2.6-7.2-5.4-10.9-7.8c-57.9-40-126.6-63.5-197.5-64.1c-5.4,0-16,0-21.4,0.2
            c-38.7,0.8-77.5,9-115.4,23.3c-5.5,2.1-11.3,4.2-16.8,6.9c-10,4.7-29.5,14.7-39.2,19.9c-120.4,66.1-185.1,199.6-177.6,332.3l0,0
            c0,0,2.3,35.3-1.8,94.2c-4.9,71.3-19.3,177.1-58.6,296.4"
                  />
                  <path
                    className="st2"
                    d="M3263,1081.2c131.7-278.3,115.9-528.1,115.9-528.1c-2.1-49-13.1-98.2-33.9-145.7
            c-42.9-98.2-118.6-174-211.2-210.5c-12.6-4.9-37.9-14-50.8-18.1c-87-27.9-184.6-20.9-274.8,18.6
            c-160.3,70-252.8,230.8-243.8,395.8l0,0c0,0,10,152.3-48.3,351.5"
                  />
                  <path
                    className="st2"
                    d="M3325.8,1039.6c112.4-262.1,97.9-488.8,97.9-488.8c-2.3-54.2-14.5-108.9-37.5-161.6
            c-4.2-9.6-8.3-19.1-13.4-28.1c-7.5-13.2-23.2-39.2-31.5-51.7c-17.6-26.9-39.5-50.4-62.8-71.6c-3.1-2.8-9.3-8.3-12.6-11.1
            c-126.6-110.3-311.4-142.6-475.7-70.8c-177.9,77.8-280.7,256.2-270.8,439.3l0,0c0,0,6.9,87.3-16.8,215.6
            c-2,10.9-6.2,32.8-8.5,43.7c-3.1,15-6.7,30.5-10.8,46.5"
                  />
                </g>
              </g>
              <g>
                <defs>
                  <path
                    id="SVGID_9_"
                    d="M3804.4,926.1L3647.4,641h-44.1v285.1h-183.9V170.9h308.7c59.5,0,110.3,10.4,152.2,31.2
            c42,20.8,73.3,49.3,94.1,85.5c20.8,36.2,31.2,76.6,31.2,121c0,50.2-14.2,95-42.5,134.5c-28.3,39.4-70.1,67.4-125.3,83.9
            L4012,926.1H3804.4z M3603.3,510.9h114c33.7,0,59-8.2,75.8-24.7c16.8-16.5,25.3-39.8,25.3-69.9c0-28.7-8.4-51.3-25.3-67.8
            c-16.9-16.5-42.1-24.7-75.8-24.7h-114V510.9z"
                  />
                </defs>
                <clipPath id="SVGID_10_">
                  <use xlinkHref="#SVGID_9_" style={{ overflow: "visible" }} />
                </clipPath>
                <g className="st6">
                  <path
                    className="st1"
                    d="M3162.8,453c-14.2,107.9,1.3,221,51.1,326.7c70.8,150.6,197.1,258.3,343.7,306.7c4.1,1.3,12.2,3.9,16.3,5.2
            c28.6,9,57.9,16,87.8,19.7c13.7,1.6,41.3,4.4,55,5.4c108.5,8,220.2-19.1,321.5-77.2c144.4-82.7,240.6-217.5,277.9-367.2"
                  />
                  <path
                    className="st2"
                    d="M3834,1156.3c77.5-10.9,154.4-36.4,226.4-77.8c87.3-50.1,158.3-117.8,210.9-196"
                  />
                  <path
                    className="st2"
                    d="M3281.2,169.2c-60.9,244.3-27.6,435.6-27.6,435.6c9,58.9,28.9,117,60.2,171.9
            c136.6,238.4,440.8,321,679.4,184.4c185.2-106.1,276.3-313.2,244-512.1l0,0c0,0-17.6-101.3-3.9-250.7"
                  />
                  <path
                    className="st1"
                    d="M3216.3,275.2c-31.8,195-7.2,336.7-7.2,336.7l0,0c9.8,64.1,31.5,127.8,65.6,187.5
            c149,260.1,480.8,350.2,740.9,201.1c75.7-43.4,138.9-101.2,182.3-170.4c7.5-12.1,22-36.7,29-49.1c45.4-81.6,67.6-175.8,61.5-269.3
            c-1.1-17.3-4.4-52.1-6.7-69.4c-5.9-43.4-11.9-68.2-8.5-175.1"
                  />
                  <path
                    className="st2"
                    d="M3507.9-8.7c-58.1,146.4-79.2,284.6-83.6,390.1c-0.3,9.6-1,28.7-1.3,38.2c-1,40.5,0.7,74.7,2.6,100.7
            c2.6,35.9,6.2,56.3,6.2,56.3l0,0c5.7,37.9,18.4,75.2,38.5,110.3c87.1,152.1,281,204.7,433.1,117.5
            c118.3-67.9,176.4-200.4,155.4-327.5l0,0c0,0-33.1-188.2,25.8-429.9"
                  />
                  <path
                    className="st2"
                    d="M3695-57.1c-134,319.5-84.7,605.8-84.7,605.8l0,0c2.4,16.6,7.8,33.1,16.8,48.6
            c37.5,65.6,121.3,88.3,186.9,50.8c51.6-29.5,76.7-87.6,66.7-143l0,0c0,0-43.2-244,55.7-533"
                  />
                  <path
                    className="st2"
                    d="M3737.6-59.2c-131.4,317.3-82.9,600.4-82.9,600.4c1.5,11.6,5.2,23,11.4,33.8c25.1,43.9,81.3,59.2,125.3,33.9
            c34.9-19.9,51.7-59.4,44.6-96.8c0,0-45.2-254.6,62-551.6"
                  />
                  <path
                    className="st2"
                    d="M3779.2-58.4c-127.5,315.1-79.5,595.7-79.5,595.7c4.4,25.5,28.6,42.4,54,38c25.5-4.4,42.4-28.6,38-54
            c0,0-4.4-22.7-6.9-62.7c-0.7-10.6-1.6-31.7-2.1-42.3c-0.8-21.7-1-46.3,0-73.4c0.5-10.8,1.5-32.3,2.1-43.1
            c2.3-38.7,7-81.3,14.9-126.5c0.8-5.1,2.8-15.3,3.6-20.4c11.6-63.3,29.7-131.4,56.1-201.4"
                  />
                  <path
                    className="st2"
                    d="M3819.8-54.7c-121.7,310.4-73.9,584.1-73.9,584.1"
                  />
                  <path
                    className="st2"
                    d="M3650.9-51.6c-134.6,320-85.2,607.3-85.2,607.3l0,0c3.3,21.9,10.4,43.6,22.2,64c6.5,11.4,13.4,22.5,22.7,31.3
            c10.4,9.8,32.6,27.9,44.6,35.9c51.6,35.3,123.1,33.5,181.3,0.2c68.2-39.2,101.7-115.9,88.9-189.2l0,0c0,0-14.9-77.4-8-196.3
            c0.7-12.2,2.3-36.9,3.3-49.1c4.7-60.7,15.8-129.4,34.9-202.9c5.4-20.9,11.6-42.1,18.4-63.5"
                  />
                  <path
                    className="st2"
                    d="M3605.2-42.1c-11.1,26.6-21.2,52.7-29.7,78.8c-4.2,12.9-12.2,39-16.2,52.1c-46.5,156.3-50.9,295.6-46.5,381.9
            c0.7,13.2,2.3,39.7,3.3,52.9c2,25.5,4.7,39.5,4.7,39.5l0,0c4.1,27.3,13.1,54.2,27.6,79.5c62.3,108.9,201.1,146.6,309.9,84.2
            c85-48.6,126.6-143.9,111-235.2c0,0-38.7-219,41.3-487.8"
                  />
                  <path
                    className="st2"
                    d="M3557.7-28.1c-129.7,316.1-81.3,597.6-81.3,597.6c4.9,32.6,15.8,64.8,33.1,95.1
            c66.7,116.7,208.9,170.2,329,120.6c10.8-4.4,32.2-14.4,42.4-19.7c62.2-32.2,106.1-88.5,123.2-151c3.8-13.4,9.6-40.8,11.9-54.5
            c4.1-24.8,2.3-50.6-2-75.9l0,0c0,0-36.1-204.3,33.6-460.6"
                  />
                  <path
                    className="st2"
                    d="M3455.9,17.8c-114.1,303.1-68.5,566-68.5,566l0,0c6.5,43.1,21.1,85.7,43.9,125.8
            c32.5,56.6,77.7,101,130.2,132c3.9,2.3,7.8,4.6,11.8,6.7c61.9,33.5,132.8,49.5,203.3,42.4c5.4-0.5,16-1.8,21.2-2.4
            c38.4-4.9,76.2-17.3,112.3-35.7c5.4-2.8,10.8-5.4,16-8.6c9.3-5.7,27.7-17.8,36.7-24.2c112.6-78.7,162.4-218.4,140.7-349.6l0,0
            c0,0-6.2-34.9-8.3-93.8c-2.8-71.3,0.2-178.1,26.3-301.1"
                  />
                  <path
                    className="st2"
                    d="M3400.9,53.2c-100.9,290.8-58.1,537.4-58.1,537.4c7.3,48.3,23.7,96.3,49.4,141.3
            c53.4,93,136.8,160.3,232.6,186.5c13.1,3.6,39.2,9.8,52.4,12.6c89.4,18.4,185.7,0.8,271.2-48.1c151.8-87,226.4-256.7,199.6-419.8
            l0,0c0,0-26.4-150.3,10.1-354.8"
                  />
                  <path
                    className="st2"
                    d="M3342.8,101.3c-83.6,272.7-44.7,496.5-44.7,496.5c8.2,53.7,26.3,106.7,54.8,156.7
            c5.2,9.1,10.4,18.1,16.5,26.6c8.8,12.2,27.3,36.4,36.9,48c20.6,25,44.7,46,70.2,64.5c3.4,2.4,10.3,7.3,13.7,9.6
            c137.9,96,325.1,108.2,480.6,18.9c168.4-96.6,251.3-284.9,221.8-465.9l0,0c0,0-16.2-86-6.7-216.1c0.8-11.1,2.6-33.3,3.6-44.4
            c1.5-15.3,3.4-31.2,5.7-47.3"
                  />
                </g>
              </g>
            </svg>
          </Link>
        </div>
        <div style={{ textAlign: "center", padding: "5% 10%" }}>
          <SEO title="Twoja wiadomość została wysłana!" />
          <h1 className="is-size-1" style={{ color: "#00ff00" }}>
            Twoja wiadomość została wysłana.
          </h1>
          <br />
          <h2 className="is-size-4">
            Nasz zespół niedługo się z Tobą skontaktuje!
          </h2>
          <br />
          <p className="is-size-5">Dziękujemy!</p>
          <br />
          <br />
          <button
            className="button rexer-button is-size-4"
            onClick={() => {
              window.location.href = "/";
            }}
            aria-label="delete"
          >
            Wróć na stronę
          </button>
        </div>
        <Newsletter />
      </Layout>
    );
  }
}

export default SucessPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
